@import "colors";

.pos-hours {
  .section {
    margin-bottom: 40px;
  }
}
.schedule {
  &.section {
    max-width: 980px;
  }
  .day {
    margin-bottom: 20px;
  }
  .name {
    font-weight: 500;
    padding-right: 10px;
    width: 10%;
    max-width: 90px;
  }
  .hours {
    width: 100%;
    margin-right: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    & > div {
      line-height: 28px;
      text-align: center;
      white-space: nowrap;
    }
  }
  .open {
    background: chartShimmer();
  }
  .closed {
    background: chartFirework(.2);
    &.--full {
      color: $greyText2;
      font-style: italic;
      width: 100%;
    }
  }
  @mixin width-classes($prefix, $step, $max) {
    @for $i from 0 through $max {
      // Calcule le pourcentage en utilisant la formule
      $percentage: calc($i * $step / $max);

      // Formater le pourcentage avec deux décimales
      $formatted-width: calc($percentage * 100%);
      $formatted-width: calc(round(calc($formatted-width * 100)) / 100);

      .#{$prefix}#{$i} {
        width: $formatted-width;
      }
    }
  }

  // Utilisation du mixin pour générer les classes
  @include width-classes('--width-', 1, 10000);



  &.hour-type2 {
    .open {
      background: rgba(15, 104, 116, .8);
      color: #fff;
    }
    .closed {
      color: #fff;
    }
  }

  &.hour-type3 {
    .open {
      background: rgba(0,1,107,.8);
      color: #fff;
    }
  }
}
#hour {
  text-align: center;
  width: 500px;
  h3 {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .hours {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .morning, .afternoon {
    width: 50%;
    input {
      width: 100px;
    }
  }
  .period {
    margin-bottom: 20px;
  }
  .buttons {
    padding-top: 20px;

    a, button {
      display: block;
      margin: 0 auto 10px;
      width: 100px;
    }
    button {
      width: 150px;
      height: 22px;
    }
  }
}

.schedule.duplicate {
  .hours > div {
    line-height: 17px;
  }
}
#duplicateHour {
  label.disabled {
    color: $greyText2
  }
}